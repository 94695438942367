import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Stack, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../../components/Iconify';
import conObj from '../../../../utils/connection-assistant';
import useLocales from '../../../../hooks/useLocales';
import palette from '../../../../theme/palette';
import CustomModal from '../../../../components/CustomModal';
import { StyledTextField } from '../../../GlobalStyles';

const Label = styled(Typography)({
  fontSize: '14px',
  fontFamily: 'sans-serif',
  fontWeight: '600',
});

const LabelSpan = styled('span')({
  color: palette.visitor.dialog.span,
  fontSize: '14px',
  fontWeight: '600',
});

const snackbarOptionsSuccess = { variant: 'success', autoHideDuration: 5000 };
const snackbarOptionsError = { variant: 'error', autoHideDuration: 5000 };

const ForgotPasswordDialog = ({ openForgotPasswordDialog, setOpenForgotPasswordDialog }) => {
  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const [email, setEmail] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    if (!email.match('[a-z0-9]+@[a-z]+.[a-z]{2,3}')) {
      enqueueSnackbar(translate('enter_valid_email'), snackbarOptionsError);
      return;
    }

    setIsLoading(true);

    conObj
      .post(`forgot.json`, { 'user-email': email.trim() })
      .then((res) => {
        if (res.status === 200) {
          const { msg, status } = res.data?.data;
          if (status === 1) {
            enqueueSnackbar(translate('email_sent'), snackbarOptionsSuccess);
          }
        }
      })
      .catch((err) => {
        const snackbarOptions = { variant: 'error', autoHideDuration: 5000 };
        enqueueSnackbar(err.message, snackbarOptions);
        console.log(`Server Error: ${err.message}`);
      })
      .then(() => {
        setOpenForgotPasswordDialog(false);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <CustomModal
      open={openForgotPasswordDialog}
      onClose={() => setOpenForgotPasswordDialog(false)}
      title={translate('forgot_password')}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ mb: 2 }}>
          <Label fontWeight={600}>{translate('email')} <LabelSpan>*</LabelSpan></Label>
          <StyledTextField
            name="email"
            value={email}
            placeholder={translate('fill_up_here')}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <Iconify icon={'mdi-light:email'} width={24} height={24} sx={{ color: palette.home.iconColor }} />
              ),
            }}
          />
        </Box>

        <LoadingButton
          sx={{
            width: '100%',
            backgroundColor: palette.button.bg,
            color: '#fff',
            '&:focus,:hover': { backgroundColor: palette.button.bg },
          }}
          onClick={() => onSubmit()}
          loading={isLoading}
        >
          {translate(`confirm`)}
        </LoadingButton>
      </Box>
    </CustomModal>
  );
};

ForgotPasswordDialog.propTypes = {
  openForgotPasswordDialog: PropTypes.bool,
  setOpenForgotPasswordDialog: PropTypes.func,
};

export default ForgotPasswordDialog;
