import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// @mui
import { styled } from '@mui/material/styles';
import { AppBar, Alert, Box, Button, ButtonBase, Stack, Toolbar, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// import { loadCaptchaEnginge } from 'react-simple-captcha';
// hooks
import { m } from 'framer-motion';
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import LoginSignupDialog from './visitor/LoginSignupDialog';
import ForgotPasswordDialog from './forgotpassword/ForgotPasswordDialog';
import ResetPasswordDialog from './forgotpassword/ResetPasswordDialog';
import { useDispatch, useSelector } from '../../../redux/store';
import { openLoginSignup } from '../../../redux/slices/authPages';
import { openTransactionsDialog } from '../../../redux/slices/transactionsDialog';

import Image from '../../../components/Image';
import NotificationPopover from './NotificationPopover';
import AccountPopover from './AccountPopover';
import useLocales from '../../../hooks/useLocales';
import { fNumber, fNumberDecimal } from '../../../utils/formatNumber';
import palette from '../../../theme/palette';
import { ThemeButton } from '../../GlobalStyles';
import useAuth from '../../../hooks/useAuth';
import LanguagePopover from './LanguagePopover';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ theme, showDownloadApp, isDesktop }) => ({
  ...cssStyles(theme).bgBlur(),
  // boxShadow: '0px 1px 5px 5px rgb(0, 0, 0, 0.2)',
  background: palette.header.bgColor,
  borderTop: `1px solid ${palette.header.border}`,
  borderBottom: `1px solid ${palette.header.border}`,
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  top: showDownloadApp && !isDesktop ? 36 : 0,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  // [theme.breakpoints.up('lg')]: {
  //   height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
  //   width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
  //   ...(isCollapse && {
  //     width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
  //   }),
  //   ...(isOffset && {
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //   }),
  //   ...(verticalLayout && {
  //     width: '100%',
  //     height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  //     backgroundColor: theme.palette.background.default,
  //   }),
  // },
}));

const DownloadAppAlert = styled(Alert)(({ theme }) => ({
  fontSize: 9,
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
  padding: '0px 16px',
  position: 'fixed',
  top: 0,
  zIndex: 1,
  width: '100%',
  background: '#000',
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  onToggleCollapse: PropTypes.func,
  openAccountDrawer: PropTypes.bool,
  setOpenAccountDrawer: PropTypes.func,
};

export default function DashboardHeader({
  isOpenSidebar = false,
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
  // onCloseSidebar,
  onToggleCollapse,
  openAccountDrawer,
  setOpenAccountDrawer,
  showDownloadApp,
  setShowDownloadApp,
}) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const { currentLang } = useLocales();

  const { isAuthenticated, logout } = useAuth();

  const isDesktop = useResponsive('up', 'lg');

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { isOpenLoginSignupDialog } = useSelector((x) => x.authPages);

  const { memberBalance } = useSelector((x) => x.lookup);

  const [openForgotPasswordDialog, setOpenForgotPasswordDialog] = useState(false);

  const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

  const navigate = useNavigate();

  const [showWallet, setShowWallet] = useState(false);

  useEffect(() => {
    // if (localStorage.getItem('referralCode') && !localStorage.getItem('accessToken')) {
    //   setTimeout(() => {
    //     dispatch(openLoginSignup({ open: true, isLogin: false }));
    //   }, 1000);
    // }
    // Check if got resetpassword token
    if (localStorage.getItem('resetToken')) {
      setOpenResetPasswordDialog(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('accessToken')) {
      // getProfile();
    } else {
      console.log('loggedoff');
    }
  }, [localStorage.getItem('accessToken')]);

  const balanceDisplay = useMemo(() => {
    const rawBalance = memberBalance?.displayBalance !== '0' ? memberBalance?.displayBalance : '0.00';
    if (showWallet) {
      return rawBalance;
    }

    return rawBalance.toString().replace(/\d/g, '*');
  }, [showWallet, memberBalance]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/', { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      {showDownloadApp && !isDesktop && (
        <DownloadAppAlert
          icon={<Iconify icon={'line-md:download-loop'} sx={{ color: '#fff' }} />}
          variant="filled"
          severity="info"
          sx={{
            '.MuiAlert-message': {
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            },
          }}
        >
          <Box
            onClick={() => {
              navigate('/download');
              window.location.reload();
            }}
            sx={{ flex: 1, display: 'flex', alignItems: 'center' }}
          >
            <p style={{ whiteSpace: 'nowrap', fontSize: 9 }}>{translate('download_message')}</p>
            <ButtonBase
              sx={{
                ml: 1,
                border: 1,
                background: '#000',
                color: 'red',
                borderRadius: '4px',
                p: '2px 4px',
                fontSize: '9px',
                fontWeight: 600,
              }}
            >
              {translate('download')}
            </ButtonBase>
          </Box>
          <Iconify
            onClick={() => setShowDownloadApp(false)}
            icon={'ic:round-close'}
            sx={{ color: '#fff', width: '20px', height: '20px', cursor: 'pointer' }}
          />
        </DownloadAppAlert>
      )}
      <RootStyle
        isCollapse={isCollapse}
        isOffset={isOffset}
        verticalLayout={verticalLayout}
        showDownloadApp={showDownloadApp}
        isDesktop={isDesktop}
      >
        <Toolbar
          sx={{
            minHeight: '100% !important',
            pl: { lg: 1 },
            pr: { lg: 2 },
          }}
        >
          {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />}

          <Box sx={{ flex: { xs: 1, lg: 0 } }} onClick={onOpenSidebar}>
            <Iconify icon={'ic:round-menu'} sx={{ color: '#fff', width: '24px', height: '24px', cursor: 'pointer' }} />
          </Box>

          {!isDesktop && (
            <Stack direction="row" alignItems={'center'} justifyContent="center" sx={{ flex: { xs: 1, lg: 0 } }}>
              <Box sx={{ width: { sm: '3rem', md: '7rem' } }} onClick={() => navigate('/home')}>
                <Image
                  src={require('../../../assets/brand-logo.png')}
                  alt=""
                  sx={{ cursor: 'pointer', width: '100px', height: '30px' }}
                />
              </Box>
            </Stack>
          )}

          <Stack direction={'row'} spacing={3} flex={1} justifyContent={'end'} alignItems={'center'}>
            {isDesktop && (
              <LanguagePopover
                CustomBtn={
                  <Button size="small" sx={{ display: 'flex', alignItems: 'center' }}>
                    <Image
                      src={require('../../../assets/menu/nav-lang.png')}
                      sx={{
                        mx: 'auto',
                        cursor: 'pointer',
                        filter:
                          'brightness(0) saturate(100%) invert(19%) sepia(86%) saturate(5918%) hue-rotate(357deg) brightness(91%) contrast(99%)',
                        height: '30px',
                        width: '30px',
                      }}
                    />
                    <Typography sx={{ color: 'white', fontSize: 'inherit' }}>{translate('language')}</Typography>
                  </Button>
                }
              />
            )}

            {isDesktop && !localStorage.getItem('accessToken') && (
              <>
                <LoadingButton
                  variant="outlined"
                  sx={{
                    padding: '5px 20px',
                    border: '1px solid #fff',
                    color: '#fff',
                    borderRadius: '4px',
                    '&:hover': {
                      borderColor: '#fff',
                    },
                  }}
                  onClick={() => dispatch(openLoginSignup({ open: true, isLogin: true }))}
                >
                  {translate('login')}
                </LoadingButton>
                <ThemeButton
                  sx={{
                    padding: '5px 20px',
                  }}
                  onClick={() => navigate('/auth/register')}
                >
                  {translate('sign_up')}
                </ThemeButton>
              </>
            )}
            {isDesktop && localStorage.getItem('accessToken') && (
              <>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography>{translate('main_wallet')}</Typography>
                  <Typography>
                    {memberBalance?.currencyCode} {balanceDisplay}
                    {/* {
                    showWallet && memberBalance?.displayBalance !== undefined
                    ? memberBalance.displayBalance !== '0' ? memberBalance.displayBalance : '0.00'
                    : memberBalance?.displayBalance !== undefined
                        ? '0.00'
                        : memberBalance?.displayBalance?.toString().replace(/\d/g, '*')
                  } */}
                  </Typography>
                  <Iconify
                    icon={showWallet ? 'eva:eye-off-fill' : 'eva:eye-fill'}
                    onClick={() => setShowWallet(!showWallet)}
                    sx={{
                      color: '#ff0000',
                      fontSize: '20px',
                      cursor: 'pointer',
                    }}
                  />
                </Stack>
                <ThemeButton
                  size="small"
                  sx={{
                    padding: '1px',
                  }}
                  onClick={() => navigate('/user/wallet')}
                >
                  {translate('deposit')}
                </ThemeButton>
                <Typography
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setOpenAccountDrawer(true)}
                >
                  {translate('profile')}
                </Typography>
                <Typography
                  sx={{
                    cursor: 'pointer',
                  }}
                  onClick={handleLogout}
                >
                  {translate('logout')}
                </Typography>
                <NotificationPopover />
              </>
            )}
          </Stack>

          {isOpenLoginSignupDialog.open && (
            <LoginSignupDialog setOpenForgotPasswordDialog={setOpenForgotPasswordDialog} />
          )}

          {openForgotPasswordDialog && (
            <ForgotPasswordDialog
              openForgotPasswordDialog={openForgotPasswordDialog}
              setOpenForgotPasswordDialog={setOpenForgotPasswordDialog}
            />
          )}

          {openResetPasswordDialog && (
            <ResetPasswordDialog
              openResetPasswordDialog={openResetPasswordDialog}
              setOpenResetPasswordDialog={setOpenResetPasswordDialog}
              setOpenLoginSignup={(params) => dispatch(openLoginSignup(params))}
            />
          )}
        </Toolbar>
      </RootStyle>
    </>
  );
}
