import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer } from '@mui/material';
// hooks
import { AnimatePresence, m } from 'framer-motion';
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
// config
import { NAVBAR } from '../../../config';
// components
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import palette from '../../../theme/palette';

//
import navConfig from './NavConfig';
import CollapseButton from './CollapseButton';
import Iconify from '../../../components/Iconify';
import Image from '../../../components/Image';
import { varBounce } from '../../../components/animate';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <AnimatePresence>
        <Box component={m.div} {...varBounce({ delay: 0.3 }).in} sx={{ position: 'fixed', top: 0, right: '2vw' }}>
          <CollapseButton
            onToggleCollapse={onCloseSidebar}
            collapseClick={!isOpenSidebar}
            closeIcon={<Iconify icon="mdi:times" sx={{ fontSize: '2rem' }} />}
          />
        </Box>
      </AnimatePresence>

      <Stack
        spacing={3}
        sx={{
          p: 1,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
          backgroundColor: '#000',
          borderBottom: '1px solid #ff0000',
          marginBottom: '10px',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', overflow: 'hidden' }}>
          <Image
            src={require('../../../assets/brand-logo.png')}
            sx={{
              width: '130px',
              height: '40px',
            }}
          />
        </Box>
      </Stack>

      <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} onCloseSidebar={onCloseSidebar} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: NAVBAR.DASHBOARD_WIDTH,
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{ sx: { width: NAVBAR.DASHBOARD_WIDTH, border: 'none', background: palette.navBarVertical.bg } }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open={isOpenSidebar}
          // onClose={onCloseSidebar}
          variant="persistent"
          hideBackdrop={true}
          // onMouseEnter={onHoverEnter}
          // onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              height: 'calc(100% - 64px)',
              top: { xs: 'unset', lg: '47px' },
              bottom: 0,
              width: NAVBAR.DASHBOARD_WIDTH,
              px: 1,
              pt: 0,
              background: palette.navBarVertical.bg,
              // bgcolor: '#051a12',
              boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
              border: 'none',
              boxSizing: 'border-box',
              visibility: 'unset !important',
              transform: 'unset !important',
              zIndex: 1100,
              transition: (theme) =>
                theme.transitions.create('margin', {
                  duration: theme.transitions.duration.enteringScreen,
                  easing: theme.transitions.easing.easeInOut,
                }),
              ...(isCollapse && {
                // width: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
                marginLeft: '-240px',
              }),
              // ...(collapseHover && {
              //   ...cssStyles(theme).bgBlur(),
              //   boxShadow: (theme) => theme.customShadows.z24,
              // }),
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
