import { useState } from 'react';
import PropTypes from 'prop-types';
import { Stack, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Image from '../../../components/Image';
import useLocales from '../../../hooks/useLocales';
import LanguagePopover from '../header/LanguagePopover';
import { openLoginSignup } from '../../../redux/slices/authPages';
import AccountDrawer from './AccountDrawer';

// STYLES
const BottomNavContainer = styled(Stack)(({ isAuthenticated }) => ({
    height: '64px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 100,
    right: 0,
    fontWeight: 'bold',
    backgroundColor: "#000",
    borderTop: isAuthenticated ? '1px solid #ff0000' : 'unset',
    fontSize: isAuthenticated ? '12px' : '16px'
}));
  
const BottomNav = styled(Stack)(({ position }) => ({
    cursor: 'pointer',
    color: '#fff'
}));

const BottomNavIcon = styled(Image)({
    width: '19px',
    height: '19px',
    filter: 'brightness(0) saturate(100%) invert(19%) sepia(86%) saturate(5918%) hue-rotate(357deg) brightness(91%) contrast(99%)'
});
// STYLES


// ----------------------------------------------------------------------
MobileBottomNav.propTypes = {
    isAuthenticated: PropTypes.bool,
};

export default function MobileBottomNav({ isAuthenticated, setOpenAccountDrawer }) {
    const navigate = useNavigate();
    const { translate } = useLocales();
    const dispatch = useDispatch();

    return (
        <>
            {isAuthenticated ? (
              <>
                <BottomNavContainer direction={'row'} isAuthenticated={isAuthenticated}>
                  <BottomNav 
                    flex={1} 
                    justifyContent="center" 
                    alignItems="center"
                    onClick={() => navigate('/home')}
                  >
                    <BottomNavIcon src={require('../../../assets/menu/bottom_nav/nav-icon-home.png')}/>
                    {translate('home')}
                  </BottomNav>
                  <BottomNav 
                    flex={1} 
                    justifyContent="center" 
                    alignItems="center"
                    onClick={() => navigate('/promotion')}
                  >
                    <BottomNavIcon src={require('../../../assets/menu/bottom_nav/nav-icon-promotion.png')}/>
                    {translate('promotions')}
                  </BottomNav>
                  <BottomNav 
                    flex={1} 
                    justifyContent="center" 
                    alignItems="center"
                    onClick={() => {
                      if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
                      else navigate('/user/wallet');
                    }}
                  >
                    <BottomNavIcon src={require('../../../assets/menu/bottom_nav/nav-icon-deposit.png')}/>
                    {translate('deposit')}
                  </BottomNav>
                  <BottomNav 
                    flex={1} 
                    justifyContent="center" 
                    alignItems="center"
                    onClick={() => {
                      if (!isAuthenticated) dispatch(openLoginSignup({ open: true, isLogin: true }));
                      else setOpenAccountDrawer(true)
                      // else navigate('/user/account');
                    }}
                  >
                    <BottomNavIcon src={require('../../../assets/menu/bottom_nav/nav-icon-mine.png')}/>
                    {translate('profile')}
                  </BottomNav>
                </BottomNavContainer>
              </>
            ): (
              <BottomNavContainer direction={'row'}>
                <BottomNav flex={1} sx={{ backgroundColor: '#ddd' }} justifyContent="center" alignItems="center">
                  <LanguagePopover />
                </BottomNav>
                <BottomNav
                  flex={1}
                  sx={{ backgroundColor: '#000' }}
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => dispatch(openLoginSignup({ open: true, isLogin: true }))}
                >
                  {translate('login')}
                </BottomNav>
                <BottomNav
                  flex={1}
                  sx={{ backgroundColor: '#ff0000' }}
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => navigate('/auth/register')}
                >
                  {translate('sign_up')}
                </BottomNav>
              </BottomNavContainer>
            )}
          </>
    );
}
