import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import ReactGA from 'react-ga4';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { LiveChatWidget } from '@livechat/widget-react';
import ReactPixel from 'react-facebook-pixel';
// hooks
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';

//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import Footer from './Footer';

import palette from '../../theme/palette';

import {
  getAngPauPromotion,
  getGameCategsAndCompanies,
  getSiteSettings,
  getUserMemberBalance,
} from '../../redux/slices/lookup';

import { setGameCategories } from '../../redux/slices/game';

import conObj from '../../utils/connection-assistant';

import { usePageVisibility } from '../../hooks/usePageVisibility';
import MobileBottomNav from './navbar/MobileBottomNav';
import { closeLoadingScreen, openLoadingScreen } from '../../redux/slices/settings';
import AccountDrawer from './navbar/AccountDrawer';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ showDownloadApp, isDesktop, collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: 45,
  paddingBottom: '64px',
  backgroundImage: `url(${require('../../assets/main_bg.jpg')})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'repeat-y',
  [theme.breakpoints.down('sm')]: {
    paddingTop: showDownloadApp && !isDesktop ? HEADER.MOBILE_HEIGHT + 36 : HEADER.MOBILE_HEIGHT,
  },
  [theme.breakpoints.up('lg')]: {
    // paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));
// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  const { collapseClick, isCollapse, onToggleCollapse } = useCollapseDrawer();

  const { siteSettings } = useSelector((x) => x.lookup);

  const isVisible = usePageVisibility();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const [openAccountDrawer, setOpenAccountDrawer] = useState(false);

  const [showDownloadApp, setShowDownloadApp] = useState(true);

  const verticalLayout = themeLayout === 'vertical';

  useEffect(() => {
    // Check if the PWA is already installed (usually stored in localStorage or can be checked with appinstalled event)
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setShowDownloadApp(false);
    }

    // Listen for the `appinstalled` event
    window.addEventListener('appinstalled', () => {
      setShowDownloadApp(false);
    });

    const promises = [getGameCategsAndCompanies(), getSiteSettings(), getAllGames(), getAngPauPromotion()];

    if (isAuthenticated) promises.push(getUserMemberBalance());

    dispatch(openLoadingScreen());
    Promise.all(promises).finally(() => dispatch(closeLoadingScreen()));

    return () => {
      window.removeEventListener('appinstalled', () => {
        setShowDownloadApp(false);
      });
    };
  }, []);

  /**
   * Call getUserMemberBalance() when tab is active and isAuthenticated
   * to keep the member balance updated
   */
  useEffect(() => {
    if (isVisible && isAuthenticated) getUserMemberBalance();
  }, [isVisible, isAuthenticated]);

  // useEffect(() => {
  //   console.log(openPageLoadingCount);
  // }, [openPageLoadingCount])

  useEffect(() => {
    try {
      if (siteSettings?.config_analytics) {
        const _ga = siteSettings?.config_analytics?.split(',');

        // Multiple products (previously known as trackers)
        ReactGA.initialize(
          _ga.map((item) => ({
            // measurement ID
            trackingId: item,
            // gaOptions: {...}, // optional
            // gtagOptions: {...}, // optional
          }))
          //   [
          //   {
          //     // measurement ID
          //     trackingId: _ga[0],
          //     // gaOptions: {...}, // optional
          //     // gtagOptions: {...}, // optional
          //   },
          // ]
        );
      }

      if (siteSettings?.config_livechat) {
        // const respondIo = JSON.parse(siteSettings?.config_livechat);
        // const script = document.createElement('script');
        // script.id = respondIo?.id;
        // script.src = respondIo?.src;
        // script.async = true;
        // document.head.appendChild(script);
      }

      if (siteSettings?.config_fb_pixel) {
        const metaPixel = siteSettings.config_fb_pixel.replace(/[^\d,]+/g, '').split(',');
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };

        metaPixel.forEach((_pixel) => {
          ReactPixel.init(_pixel, options);

          ReactPixel.pageView(); // For tracking page view
        });
      }
    } catch (error) {
      console.warn(`3RD PARTY INTEGRATION ERROR: ${error?.message}`);
    }
  }, [siteSettings]);

  const getAllGames = () => {
    conObj.post('games/all.json?limit=1').then((res) => {
      if (res.status === 200) {
        // res.data?.data?.data
        // set appversion to localstorage
        // res.data?.data.appversion
        const _allGames = res.data?.data?.data;

        dispatch(
          setGameCategories(
            _allGames
              .filter((f) => f.key.toLowerCase().indexOf('gcat') !== -1)
              .map((item) => ({ key: item.key, name: item.name, slug: item.slug }))
          )
        );
      }
    });
  };

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          verticalLayout={verticalLayout}
          showDownloadApp={showDownloadApp}
          setShowDownloadApp={setShowDownloadApp}
        />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        // backgroundColor: '#0b1712',
      }}
    >
      <DashboardHeader
        isCollapse={isCollapse}
        isOpenSidebar={open}
        onOpenSidebar={() => setOpen(true)}
        onCloseSidebar={() => setOpen(!open)}
        onToggleCollapse={onToggleCollapse}
        openAccountDrawer={openAccountDrawer}
        setOpenAccountDrawer={setOpenAccountDrawer}
        showDownloadApp={showDownloadApp}
        setShowDownloadApp={setShowDownloadApp}
      />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick} showDownloadApp={showDownloadApp} isDesktop={isDesktop}>
        {/* <img src={require('../../assets/bg.png')} style={{ position: 'absolute' }} alt="" /> */}
        <Outlet />
        <Footer />
        {/* BOTTOM NAVIGATION */}
        {!isDesktop && isAuthenticated !== null && (
          <MobileBottomNav
            isAuthenticated={isAuthenticated}
            openAccountDrawer={openAccountDrawer}
            setOpenAccountDrawer={setOpenAccountDrawer}
          />
        )}
        {/* BOTTOM NAVIGATION */}
        {/* ACCOUNT DRAWER  */}
        {isAuthenticated !== null && <AccountDrawer open={openAccountDrawer} setOpen={setOpenAccountDrawer} />}
        {/* ACCOUNT DRAWER  */}
      </MainStyle>

      {siteSettings?.config_livechat && siteSettings?.config_livechat?.length === 8 ? (
        <LiveChatWidget
          license={siteSettings?.config_livechat}
          onReady={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer && data.state.visibility === 'minimized') {
              widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
          onVisibilityChanged={(data) => {
            const widgetContainer = document.getElementById('chat-widget-container');

            if (widgetContainer) {
              if (data.visibility === 'maximized') {
                widgetContainer.classList.remove('adjust-bottom-space');
              } else widgetContainer.classList.add('adjust-bottom-space');
            }
          }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default DashboardLayout;
