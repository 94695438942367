import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Dialog, DialogTitle, DialogActions, IconButton, Slide, useTheme, alpha, Typography } from '@mui/material';
import Iconify from './Iconify';
import { DialogAnimate, varFade } from './animate';
// utils
import cssStyles from '../utils/cssStyles';

// ----------------------------------------------------------------------

CustomModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  actions: PropTypes.node,
  onClose: PropTypes.func,
  hasClose: PropTypes.bool,
  children: PropTypes.node,
  isBackdropClosable: PropTypes.bool,
  hasTransition: PropTypes.bool,
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CustomModal({
  children,
  title = '',
  actions,
  open,
  onClose,
  hasClose = true,
  isBackdropClosable = true,
  // hasTransition = true,
  ...other
}) {
  const theme = useTheme();

  const handleClose = (e, r) => {
    if (!isBackdropClosable && r === 'backdropClick') {
      return false;
    }

    if (!isBackdropClosable && r === 'escapeKeyDown') {
      return false;
    }

    onClose(e, r);
    return true;
  };

  return (
    <DialogAnimate
      fullWidth
      open={open}
      onClose={handleClose}
      scroll={'body'}
      PaperProps={{ sx: { borderRadius: '4px !important', backgroundColor: '#000', border: '1px solid #ff0000' } }}
      // {...(hasTransition ? { TransitionComponent: Transition } : null)}
      {...other}
      // transitionDuration={{ enter: 100, exit: 0 }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: title ? 'space-between' : 'end',
          alignItems: 'center',
          p: title ? 2 : 1,
          backgroundColor: title ? '#000' : 'none',
          borderBottom: '1px solid #ff0000'
        }}
      >
        {title && <Typography>{title}</Typography>}
        {hasClose && (
          <IconButton
            aria-label="close"
            onClick={() => onClose()}
            color="primary"
            size="small"
            sx={{
              '&:hover': {
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.8),
              },
            }}
          >
            <Iconify icon={'ep:close'} sx={{ fontSize: '1.2rem', color: title ? '#fff' : 'black' }} />
          </IconButton>
        )}
      </DialogTitle>

      {children}
      {actions && <DialogActions>{actions}</DialogActions>}
    </DialogAnimate>
  );
}
