/* eslint-disable camelcase */
// @mui
import { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { getYear } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import Image from '../../components/Image';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import palette from '../../theme/palette';
import useResponsive from '../../hooks/useResponsive';

const StyledTypography = styled(Typography)(({ theme }) => ({
  '&': {
    color: 'white',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: 'white',
  fontWeight: 700,
  fontSize: '.7rem',
}));

const FooterTitle = styled(Typography)({
  fontSize: '1.78rem',
  color: '#ff0000',
});

const FooterCaption = styled(Typography)({
  fontSize: '.7rem',
  marginBottom: '16px',
});

const StyledImage = styled(Image)(({ theme }) => ({
  width: 27,
  height: 31,
  objectFit: 'contain',
}));

const StyledLink = styled(Link)({
  textDecoration: 'underline',
  color: '#fff',
  fontSize: '12px',
});

const CustomScrollbar = styled(Grid)({
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'grey #2b2b2b',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#2b2b2b',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#f48fb1',
    borderRadius: '10px',
    border: '2px solid #2b2b2b',
  },
});

const Footer = () => {
  const { siteSettings } = useSelector((x) => x.lookup);

  const { enqueueSnackbar } = useSnackbar();

  const { translate } = useLocales();

  const isDesktop = useResponsive('up', 'md');

  const navigate = useNavigate();

  const {
    config_footer_copyright,
    config_phonenumber,
    // config_telegram,
    // config_wechat,
    // config_whatsapp,
    // config_facebook,
    // config_messenger,
    // config_viber,
    // config_twitter,
    // config_instagram,
    // config_youtube,
    // config_line,
  } = siteSettings || {};

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar('Copied!');
  };

  const copyrightText = useMemo(() => {
    if (siteSettings?.config_footer_copyright) {
      const newText = siteSettings?.config_footer_copyright
        .replace('[year]', getYear(new Date()))
        .replace('[name]', siteSettings?.config_name);

      console.log(newText);

      return newText;
    }

    return '';
  }, [siteSettings?.config_footer_copyright, siteSettings?.config_name]);

  const openSocial = (link) => {
    window.open(
      link,
      '_blank'
      // 'noopener noreferrer'
    );
  };

  const displayGameProvidersComponent = (items) => {
    const midIndex = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, midIndex);
    const secondHalf = items.slice(midIndex);

    return (
      <CustomScrollbar
        container
        spacing={1}
        sx={{ overflowX: 'scroll', mt: 2, ml: 0, width: '100%', padding: '0 20px 20px 10px' }}
      >
        <Grid container item xs={12} spacing={1} wrap="nowrap">
          {firstHalf.map((item, index) => (
            <Grid item>
              <Image key={index} src={item} alt="Providers" sx={{ width: '6rem' }} />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={12} spacing={1} wrap="nowrap">
          {secondHalf.map((item, index) => (
            <Grid item>
              <Image key={index} src={item} alt="Providers" sx={{ width: '6rem' }} />
            </Grid>
          ))}
        </Grid>
      </CustomScrollbar>
    );
  };

  return (
    <Box sx={{ overflow: 'hidden' }} mb={2}>
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: palette.footer.bgColor,
          py: '1rem',
        }}
      >
        <Stack direction={isDesktop ? 'row' : 'column'} spacing={3}>
          <Stack direction={'row'} flex={1}>
            <Stack flex={1}>
              <FooterText>{translate('payment_methods')}</FooterText>
              <Box style={{ marginTop: 10 }}>
                <Grid container spacing={1}>
                  {_paymentMethod.map((src, idx) => (
                    <Grid item key={idx}>
                      <StyledImage src={src} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Stack>
            <Box flex={1}>
              <FooterText>{translate('customer_support')}</FooterText>
              <Stack style={{ marginTop: 10 }} direction={'row'} alignItems={'center'}>
                <StyledImage src={require('../../assets/powered/footer/icon-24hr.png')} />
                <FooterText sx={{ color: '#ffb90f' }}>{translate('chat_now')}</FooterText>
              </Stack>
            </Box>
          </Stack>
          <Stack direction={'row'} flex={1}>
            <Stack flex={1}>
              <FooterText>{translate('certifications')}</FooterText>
              <Box style={{ marginTop: 10 }}>
                <Stack direction="row" spacing={1}>
                  {_certifications.map((src, idx) => (
                    <Image
                      key={idx}
                      src={src}
                      sx={{
                        height: '32px',
                        width: '75px',
                        img: {
                          objectFit: 'contain',
                        },
                      }}
                    />
                  ))}
                </Stack>
                {/* <Grid container spacing={1}>
                  {_certifications.map((src, idx) => (
                    <Grid item key={idx}>
                      <Image
                        src={src}
                        sx={{
                          width: '75px',
                          height: '32px',
                          img: {
                            objectFit: 'contain',
                          },
                        }}
                        ratio={'7 * 3'}
                      />
                    </Grid>
                  ))}
                </Grid> */}
              </Box>
            </Stack>
            <Box flex={1}>
              <FooterText>{translate('community_website')}</FooterText>
              <Box style={{ marginTop: 10 }}>
                <Grid container spacing={2}>
                  {_safe.map((src, idx) => (
                    <Grid item key={idx}>
                      <StyledImage src={src} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Stack>
          <Box>
            <FooterText>{translate('gaming_license')}</FooterText>
            <Box sx={{ width: '98px' }} mt={1}>
              <Image src={require('../../assets/powered/gaming-curacao-logo.png')} ratio={'10 * 3'} />
            </Box>
          </Box>
        </Stack>
      </Container>
      <Container
        maxWidth="lg"
        sx={{
          py: '1rem',
        }}
      >
        <Stack direction={'column'} spacing={3}>
          <Box>
            <Divider sx={{ borderColor: 'rgba(0, 0, 0, 0.12)', margin: '5px 12px 20px 12px' }} />
            {[...Array(9).keys()].map((index) => (
              <Box key={index} mb={2}>
                <FooterTitle variant={isDesktop ? 'h4' : 'h2'}>{translate(`footer_title_${index + 1}`)}</FooterTitle>
                <FooterCaption variant="caption">{translate(`footer_paragraph_${index + 1}`)}</FooterCaption>
              </Box>
            ))}
          </Box>
        </Stack>
      </Container>
      <Container
        maxWidth="lg"
        sx={{
          backgroundColor: palette.footer.bgColor,
          py: '1rem',
          fontSize: '.7rem',
        }}
      >
        <Image
          src={require('../../assets/brand-logo.png')}
          alt=""
          style={{ cursor: 'pointer', width: '120px', height: 'auto' }}
        />
        <Stack direction={'row'} spacing={2} mb={1}>
          <StyledLink>{translate('responsible_gaming')}</StyledLink>
          <StyledLink>{translate('terms_and_condition')}</StyledLink>
          <StyledLink>{translate('about_us')}</StyledLink>
        </Stack>

        <Box sx={{ my: 5 }}>
          <FooterText>{translate('app_download')}</FooterText>
          <Box
            onClick={() => {
              navigate('/download');
              window.location.reload();
            }}
            style={{ marginTop: 10 }}
          >
            <Image
              src={require('../../assets/download/android_download.png')}
              sx={{ height: '40px', width: '124px' }}
            />
          </Box>
        </Box>
        <FooterCaption variant="caption">{translate(`footer_copyright`)}</FooterCaption>
      </Container>
    </Box>
  );
};

export default Footer;

const _paymentMethod = [require('../../assets/powered/payment/payment-bank.png')];

const _certifications = [
  require('../../assets/powered/footer/gaming_sasino_analyzer.png'),
  require('../../assets/powered/footer/gaming_itechlabs.png'),
];

const _safe = [require('../../assets/powered/footer/safe2.png'), require('../../assets/powered/footer/safe1_2.png')];
